body {
  font-family: 'Arial', sans-serif;
  margin: 0;
  background-color: black;
}

.App {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.main-text {
  font-size: 3rem;
  color: #ff5500;
  margin: 0;
}

.sub-text {
  font-size: 1.5rem;
  color: white;
  margin-top: 0.5rem;
}


.mailbox {
  position: absolute;
  bottom: 10px;
  right: 10px;
  color: orangered;
  display: flex;
  flex-direction: column;
  /* Items are stacked vertically */
  align-items: flex-end;
  /* Align items to the end */
}

.contact-info {
  display: flex;
  /* Inline the mail icon with the email */
  align-items: center;
  margin-bottom: 10px;
}


.contact-label {
  margin-bottom: 10px;
  /* Increased margin-bottom */
  margin-right: 40px;
  /* Added margin-top */
  color: orangered;
}


.mail-icon {
  margin-right: 5px;
}


.company-info {
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  /* This centers the div */
  text-align: center;
  color: black;
  /* Change font color to black */
  background-color: rgb(238, 238, 100);
  padding: 2px 2px;
  /* Reduced padding around the text for snug fit */
  border-radius: 10px;
  /* Adjusted rounded corners */
  display: inline-block;
  /* This ensures the background fits the content */
}

.services {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: baseline;
  /* Ensure vertical alignment is consistent */
}

.service-item {
  background-color: rgb(238, 238, 100);
  padding: 5px 10px;
  border-radius: 15px;
  color: black;
  margin: 5px;
  display: inline-block;
  width: 200px;
  /* You can adjust this for your preferred width */
  text-align: center;
  /* Center the text within the span */
}

.divider {
  margin: 0 5px;
  color: white;
  vertical-align: middle;
  /* Vertically align the divider to the middle of surrounding content */
}

.divider {
  margin: 0 5px;
  color: white;
  /* Ensure the dividers are white for visibility */
}

.dots-above {
  position: absolute;
  top: 0;
  /* Adjust as per requirement */
  left: 50%;
  transform: translateX(-50%);
  color: white;
  font-size: 24px;
  /* Adjust if needed */
}

.dots-below {
  position: absolute;
  top: 40px;
  /* Adjust as per requirement - this assumes each line is roughly 20px tall, so two lines (including the company info) would be 40px */
  left: 50%;
  transform: translateX(-50%);
  color: white;
  font-size: 24px;
  /* Adjust if needed */
}

.logo {
  width: 100px;
  /* Adjust as needed */
  height: auto;
  margin-bottom: 20px;
}

.locations {
  color: yellow;
  font-size: 1rem;
  margin-top: 5px;
  padding-right: 15px;
  text-align: center;
  /* Ensure the text aligns neatly below the email */
}


/* Mobile View Adjustments */


@media (max-width: 768px) {
  .sub-text {
    text-align: center;
    width: 100%;
    overflow-wrap: break-word;
    margin-bottom: 50px;
  }

  .company-info {
    font-size: 12px;
    /* Adjust as needed to fit the text in one line */
  }

  .divider {
    display: none;
  }

  .services {
    flex-direction: column;
    align-items: center;

  }

  .service-item {
    width: 100%;
    /* Deducting the margin */
    border-radius: 15px;
    /* More rounded edges on mobile */
    box-sizing: border-box;
    /* To ensure padding and border are included in the total width */
  }

  .service-item:not(:last-child)::after {
    content: '';
  }


  .dots-above {
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    color: white;
    font-size: 24px;
  }

  .dots-below {
    position: absolute;
    top: 60px;
    left: 50%;
    transform: translateX(-50%);
    color: white;
    font-size: 24px;
  }

}